import xhr from './xhr/';
/**
 * 预警管理相关的 API
 */
class CardService {
	// 卡系列表
	getCardType() {
		return xhr({
			method: 'post',
			url: '/kxgl/kxlb',
		});
	}

	// 卡系情况列表
	getCardOverview(data) {
		return xhr({
			method: 'post',
			url: '/kxgl/kxqk',
			params: data,
		});
	}

	// 卡系服务申请
	applyCardService(data) {
		return xhr({
			method: 'post',
			url: '/kxgl/fwsq',
			params: data,
		});
	}

	// 卡系服务状态验证
	checkCardStatus(data) {
		return xhr({
			method: 'post',
			url: '/kxgl/kkjc',
			params: data,
		});
	}

	// 卡系申请列表
	getCardApplication(data) {
		return xhr({
			method: 'post',
			url: '/kxgl/sqlb',
			params: data,
		});
	}

	// 卡系审批
	approveCardService(data) {
		return xhr({
			method: 'post',
			url: '/kxgl/fwsp',
			params: data,
		});
	}

	// 卡系记录
	getCardRecords(data) {
		return xhr({
			method: 'post',
			url: '/kxgl/kxjl',
			params: data,
		});
	}

	// 员工列表
	getStaffs(data) {
		return xhr({
			method: 'post',
			url: '/kxgl/kkyg',
			params: data,
		});
	}

	// 卡系服务
	getCardServices(data) {
		return xhr({
			method: 'post',
			url: '/kxgl/kxfw',
			params: data,
		});
	}

	// 卡系服务 - 评价
	commentService(data) {
		return xhr({
			method: 'post',
			url: '/kxgl/fwpj',
			params: data,
		});
	}

	// 卡系服务 - 寄存清单
	depositList(data) {
		return xhr({
			method: 'post',
			url: '/kxgl/xhtj/total',
			params: data,
		});
	}

	// 卡系服务 - 会员寄存清单
	memberDepositList(data) {
		return xhr({
			method: 'post',
			url: '/kxgl/xhtj/member',
			params: data,
		});
	}

	// 卡系服务 - 最近服务项目
	recentServices(data) {
		return xhr({
			method: 'post',
			url: '/kxgl/pdxt/zjfwxm',
			params: data,
		});
	}

	// 卡系服务 - 项目类型
	projectTypes(data) {
		return xhr({
			method: 'post',
			url: '/kxgl/xmlb',
			params: data,
		});
	}

	// 卡系服务 - 卡系服务寄存清单
	cardServiceList(data) {
		return xhr({
			method: 'post',
			url: '/kxgl/pdxt/kxfwjcqd',
			params: data,
		});
	}

	// 卡系服务 - 卡系管理寄存清单
	cardManagementList(data) {
		return xhr({
			method: 'post',
			url: '/kxgl/pdxt/kxgljcqd',
			params: data,
		});
	}

	// 卡系服务 - 卡系月度寄存服务预警
	cardServiceAlarm() {
		return xhr({
			method: 'post',
			url: '/kxgl/pdxt/jcfwyj',
		});
	}

	// 卡系服务 - 卡系月度寄存服务统计
	cardServiceStatistics() {
		return xhr({
			method: 'post',
			url: '/kxgl/pdxt/ydfwrw',
		});
	}

	// 卡系服务 - 卡系月度寄存服务统计
	cardServiceKpj() {
		return xhr({
			method: 'post',
			url: '/kxgl/kpj',
		});
	}

	// 服务评价 - 服务项目
	getServiceItems() {
		return xhr({
			method: 'post',
			url: '/kxgl/fwlb',
		});
	}
}

// 实例化后导出，全局单例
export default new CardService();
